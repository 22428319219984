<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <app-i18n code="home.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item
        v-if="examId"
        :to="{ path: '/exam' }"
      >
        <app-i18n code="entities.exam.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item
        v-if="examId"
        :to="{ path: `/exam/${examId}/edit` }"
      >
        <app-i18n
          code="entities.exam.edit.title"
        ></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <app-i18n :code="title"></app-i18n>
      </el-breadcrumb-item>
    </el-breadcrumb>

    <div class="app-content-page">
      <h1 class="app-content-title">
        <app-i18n :code="title"></app-i18n>
      </h1>

      <app-importer :storePath="storePath"></app-importer>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
export default {
  name: 'app-iam-importer-page',
  props: ['role', 'languageId', 'examId'],
  created() {
    if (this.examId) {
      this.setStdDefaultData({
        languageId: this.languageId,
        roles: [this.role],
        examId: this.examId,
      });
    }
  },
  computed: {
    storePath() {
      return `iam/${this.role}Importer`;
    },
    title() {
      return `iam.importer.${this.role}Title`;
    },
  },
  methods: {
    ...mapMutations({
      setStdDefaultData:
        'iam/studentImporter/SET_DEFAULT_DATA',
      setTchDefaultData:
        'iam/teacherImporter/SET_DEFAULT_DATA',
    }),
  },
};
</script>

<style></style>
